<template>
    <div class="OceanShipping">
        <!-- 海运询柜 -->
        <div class="OceanShipping_Top">
            <div class="backgroundPicture"></div>
        </div>
        <!-- 询价 -->
        <Inquiry_Component></Inquiry_Component>
        <Footer_Compontent></Footer_Compontent>
    </div>
</template>

<script>
import Inquiry_Component from '../../components/EnquiryCabinet/Inquiry_Component.vue'
import Footer_Compontent from '../../components/Footer_Compontent.vue'
export default {
    name: "OceanShipping",
    components: { Inquiry_Component, Footer_Compontent },

}
</script>

<style lang="scss" scoped>
.OceanShipping {
    width: 100%;

    .OceanShipping_Top {
        width: 100%;
        // height: 363px;

        .backgroundPicture {
            height: 158px; 
            background: url('../../assets/homePage/oceanShipping.png');
        }

        .inquiry {
            width: 1200px;
            // height: 265px;
            background: #FFFFFF;
            border-radius: 8px 8px 8px 8px;
            margin: 0 auto;
            border: 1px solid;
        }
    }
}

.footer {
    margin-top: 133px;
}
</style>